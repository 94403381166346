<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("position.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
          :placeholder="$t('Search.search')" v-model="searchItem" @keypress.enter="searchFilterItem">
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("position.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listPosition.length > 0">
          <thead>
            <tr>
              <th class="text-left">{{ $t("position.no") }}</th>
              <th class="text-left">
                {{ $t("position.name") }}
              </th>
              <!--            <th class="text-left">-->
              <!--              {{ $t("position.salary") }}-->
              <!--            </th>-->
              <!--            <th class="text-left">-->
              <!--              {{ $t("position.currency") }}-->
              <!--            </th>-->
              <th class="text-end">
                {{ $t("position.addEmployee") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(position, index) in listPosition" :key="index">
              <td>{{ pagination.current_page * 10 - 10 + index + 1 }}</td>
              <td>
                {{ position.name }}
              </td>
              <!--            <td>-->
              <!--              {{ $helpers.numberFormatter(position.salary) }}-->
              <!--            </td>-->
              <!--            <td>-->
              <!--              {{ position.currency_short }}-->
              <!--            </td>-->
              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <vs-tooltip style="float: right" ref="addTooltip">
                      <v-btn @click="EmployeePositionIndex(position.id)" outlined v-bind="attrs" v-on="on">
                        <i class="far fa-briefcase" style="font-size: 18px; color: #878787"></i>
                      </v-btn>
                      <template #tooltip> {{$t("position.addEmployee")}} </template>
                    </vs-tooltip>
                  </template>
                </v-menu>
              </td>
              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title class="btn-edit" @click="onEdit(position.id)">{{ $t("education.edit") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="btn-delete" @click="onDeleted(position.id)">{{ $t("education.delete") }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <default-table-no-result v-else />
        <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset" @paginate="fetchPosition">
        </Pagination>
        <ModalEdit fullscreen title="Edit Position">
          <template v-slot="{ close }">
            <EditPosition :position="position" @close="close" @success="fetchPosition()" />
          </template>
        </ModalEdit>

        <ModalDelete>
          <template v-slot="{ close }">
            <DeletePosition :positionId="positionId" @close="close" @success="fetchPosition()" />
          </template>
        </ModalDelete>
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import EditPosition from "@/components/position/from/EditPosition";
import DeletePosition from "@/components/position/from/DeletePosition";
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    EditPosition,
    DeletePosition,
    Loading,
    Pagination,
    defaultTableNoResult,
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,

      isLoading: true,
      modalDeletePosition: false,
      position: {},
      positionId: "",
      listPosition: [],
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchPosition();
    },
    onCreate() {
      this.$router.push({ name: "position.create" }).catch(() => { });
    },

    filterPosition(positionId) {
      return (
        this.listPosition.filter((item) => {
          return item.id == positionId;
        })[0] || {}
      );
    },

    onEdit(positionId) {
      this.position = {
        ...this.filterPosition(positionId),
      };
      this.$store.commit("modalEdit_State", true);
    },

    onDeleted(positionId) {
      this.positionId = positionId;
      this.$store.commit("modalDelete_State", true);
    },

    fetchPosition() {
      this.isLoading = true;
      this.$axios
        .get(`company/position`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.isLoading = false;
            this.listPosition = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listPosition.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
        });
    },

    EmployeePositionIndex(PositionId) {
      const { addTooltip } = this.$refs;
      if (addTooltip && addTooltip[0]) {
        addTooltip.forEach(function (item) {
          item.removeTooltip();
        });
      }
      setTimeout(() => {
        this.$router
          .push({
            name: "positionEmployee.index",
            params: {
              position_id: PositionId,
            },
          })
          .catch(() => { });
      });
    },
  },
  created() {
    this.fetchPosition();
  },
};
</script>

<style scoped lang="scss"></style>
